import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DateTime } from "luxon";

import PageLayout from "../../app/components/PageLayout";
import {
  useGetPaymentInfoQuery,
  useGetPaymentMethodQuery,
  usePutDisableAutopayMutation,
} from "../../borrower/borrowerApi";

const useStyles = makeStyles({
  body: {
    display: "block",
    margin: "20px 5%",
  },
  bodyText: {
    textAlign: "left",
  },
  h2: {
    display: "block",
    fontWeight: 500,
    fontSize: "20pt",
    margin: "20px 0 ",
    textAlign: "left",
  },
  h3: {
    display: "block",
    fontWeight: 500,
    fontSize: "16pt",
    marginBottom: "10px",
    textAlign: "left",
  },
  loanNumber: {
    textAlign: "left",
  },
  loanSection: {
    display: "block",
    padding: "20px 10px",
    backgroundColor: "#e0f1ff",
    border: "2px ridge",
    borderBottom: "none",
  },
  autopaySection: {
    display: "block",
    padding: "20px 10px",
    border: "2px ridge",
  },
  table: {
    borderCollapse: "collapse",
  },
  cellBold: {
    fontWeight: 500,
  },
  editAccountInformation: {
    textAlign: "left",
    width: "70%",
  },
  actions: {
    width: "50%",
  },
});

const ManageAutopayPage = () => {
  const classes = useStyles();
  // TODO: boot user if not correct loan number
  const navigate = useNavigate();
  const { loanId, sbaLoanNumber } = useParams();
  const { data: paymentMethodData, isLoading: paymentMethodLoading } =
    useGetPaymentMethodQuery(loanId);
  const { data: paymentInfoData, isLoading: paymentInfoLoading } =
    useGetPaymentInfoQuery(loanId);
  const [putDisableAutopay] = usePutDisableAutopayMutation();
  const [isDisableAutopayOpen, setIsDisableAutopayOpen] = useState(false);

  const handleOpen = () => {
    setIsDisableAutopayOpen(true);
  };

  const handleClose = () => {
    setIsDisableAutopayOpen(false);
  };

  const handleCancelAutopay = () => {
    putDisableAutopay({
      id: paymentMethodData.loanId,
    })
      .unwrap()
      .then(() => {
        navigate(0);
      })
      .catch(() => {
        toast.error(
          "Something went wrong. Please double-check the form. If the error persists, contact an administrator.",
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );
      });
  };

  if (paymentInfoLoading || paymentMethodLoading) {
    return <CircularProgress />;
  }

  const showPaymentMethods = paymentMethodData && paymentInfoData;

  return (
    <PageLayout>
      <Grid className={classes.body} item xs={12}>
        <Typography className={classes.h2} variant="h2">
          Manage AutoPay
        </Typography>
        <div className={classes.loanSection}>
          <Typography className={classes.loanNumber} variant="body1">
            Loan - {sbaLoanNumber}
          </Typography>
        </div>
        {!showPaymentMethods && (
          <div className={classes.autopaySection}>
            <Stack spacing={1}>
              <Typography className={classes.h3} variant="h3">
                AutoPay Account Information and Payment Schedule
              </Typography>
              <Typography className={classes.bodyText} variant="body1">
                Automate your payments to stay on top of due dates, which can
                reduce surprises and possible late fees on loans.
              </Typography>
              <Link
                className={classes.bodyText}
                href={`/autopay/${loanId}/${sbaLoanNumber}/add`}
                variant="body2"
              >
                Add an account for AutoPay
              </Link>
            </Stack>
          </div>
        )}
        {showPaymentMethods && (
          <div className={classes.autopaySection}>
            <Stack spacing={1}>
              <Typography className={classes.h3} variant="h3">
                AutoPay Account Information and Payment Schedule
              </Typography>
              <Stack direction="row" spacing={1}>
                <TableContainer className={classes.table}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography className={classes.cellBold}>
                            To
                          </Typography>
                        </TableCell>
                        <TableCell>{paymentInfoData.to}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography className={classes.cellBold}>
                            From
                          </Typography>
                        </TableCell>
                        <TableCell>{paymentInfoData.from}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography className={classes.cellBold}>
                            Amount
                          </Typography>
                        </TableCell>
                        <TableCell>
                          ${paymentInfoData.amount.toLocaleString()}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography className={classes.cellBold}>
                            Frequency
                          </Typography>
                        </TableCell>
                        <TableCell>{paymentInfoData.frequency}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography className={classes.cellBold}>
                            Next Payment Date
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {DateTime.fromISO(
                            paymentInfoData.nextPaymentDueDate
                          ).toLocaleString({
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box className={classes.editAccountInformation}>
                  <Stack className={classes.actions}>
                    <Button
                      component={Link}
                      className={classes.bodyText}
                      href={`/autopay/${loanId}/${sbaLoanNumber}/edit`}
                      variant="text"
                    >
                      Edit Account Information
                    </Button>
                    <Button onClick={handleOpen} variant="text">
                      Cancel Autopay
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          </div>
        )}
        <Dialog onClose={handleClose} open={isDisableAutopayOpen}>
          <DialogTitle>Cancel Autopay</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Confirm cancel this autopay? If you change your mind, you can
              re-enter your details later.
            </DialogContentText>
            <Button onClick={handleCancelAutopay} variant="text">
              Confirm
            </Button>
            <Button onClick={handleClose} variant="text">
              Cancel
            </Button>
          </DialogContent>
        </Dialog>
      </Grid>
    </PageLayout>
  );
};

export default ManageAutopayPage;
