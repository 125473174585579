import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import { ToastContainer } from "react-toastify";

import Header, { HEADER_HEIGHT } from "./Header";

const useStyles = makeStyles(() => ({
  root: {
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    marginTop: HEADER_HEIGHT,
  },
}));

const PageLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className="App">
      <Header />
      <Grid container component="main" className={classes.root}>
        {children}
      </Grid>
      <ToastContainer autoClose={3000} />
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node,
};

export default PageLayout;
