import { useSelector } from "react-redux";
import { useParams, Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Switch,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { authSelector } from "../../auth/authSelectors";
import {
  useGetProfileQuery,
  usePutProfileMutation,
} from "../../borrower/borrowerApi";

const useStyles = makeStyles({
  dashboardCard: {
    margin: "0 10px 10px 10px",
    textAlign: "left",
  },
});

const StatementsSidebar = () => {
  const classes = useStyles();
  const { username } = useSelector(authSelector);
  const { loanId, sbaLoanNumber } = useParams();
  const { data: profileData, isLoading } = useGetProfileQuery(username);
  const [putProfileMethod] = usePutProfileMutation();

  if (isLoading) {
    return null;
  }

  const { paperless } = profileData;

  const handleChangePaperless = (evt) => {
    const { ...rest } = profileData;
    putProfileMethod({ ...rest, paperless: evt.target.checked })
      .unwrap()
      .catch(() => {
        toast.error("Something went wrong.", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  return (
    <Card className={classes.dashboardCard}>
      <CardContent>
        <Typography variant="h6">View</Typography>
        <Divider />
        <List>
          <ListItem>
            <RouterLink to={`/statements/${loanId}/${sbaLoanNumber}`}>
              <ListItemText primary="Statements" />
            </RouterLink>
          </ListItem>
          <ListItem>
            <RouterLink to={`/notices/${loanId}/${sbaLoanNumber}`}>
              <ListItemText primary="Letters &amp; Notices" />
            </RouterLink>
          </ListItem>
          <ListItem>
            <Typography>Paperless Preference</Typography>
          </ListItem>
          <ListItem>
            <Switch
              onChange={handleChangePaperless}
              defaultChecked={paperless}
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

export default StatementsSidebar;
