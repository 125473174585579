import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import makeStyles from "@mui/styles/makeStyles";

import CopyrightLine from "../components/CopyrightLine";
import PageLayout from "../components/PageLayout";

import LoginSplash from "../../static/login.jpg";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../utils/regexValidations";
import { usePostResetPasswordMutation } from "../../borrower/borrowerApi";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${LoginSplash})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function wait(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [posted, setPosted] = useState(false);
  const [searchParams] = useSearchParams();
  const [postResetPassword] = usePostResetPasswordMutation();
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const passwordRef = useRef({});
  passwordRef.current = watch("password", "");
  const resetToken = searchParams.get("token");

  const handleResetPassword = async (data) => {
    postResetPassword({
      newPassword: data.password,
      token: resetToken,
      userName: data.email,
    })
      .unwrap()
      .then(async () => {
        setPosted(true);
        await wait(3000);
      })
      .then(() => {
        navigate("/");
      });
  };

  return (
    <PageLayout>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset password
          </Typography>
          {posted && (
            <Typography variant="body">
              Password has been reset! Redirecting to login in 3 seconds...
            </Typography>
          )}
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(handleResetPassword)}
          >
            <TextField
              {...register("email", {
                required: true,
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Does not match email format",
                },
              })}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              autoComplete="email"
              autoFocus
              error={errors.email}
              helperText={errors.email ? errors.email.message : ""}
            />
            <TextField
              {...register("password", {
                required: true,
                pattern: {
                  value: PASSWORD_REGEX,
                  message:
                    "At least 8 characters including at least 1 uppercase, 1 lowercase, 1 number and 1 special character ! @ # $ % ^ & * ( ) _ + - = { } | ~ . , ; ? /",
                },
              })}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              error={!!errors.password}
              helperText={
                errors.password
                  ? errors.password.message
                  : "At least 8 characters including at least 1 uppercase, 1 lowercase, 1 number and 1 special character ! @ # $ % ^ & * ( ) _ + - = { } | ~ . , ; ? /"
              }
            />
            <TextField
              {...register("repeatPassword", {
                validate: (value) =>
                  value === passwordRef.current || "The passwords do not match",
              })}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="repeatPassword"
              name="repeatPassword"
              label="Repeat Password"
              type="password"
              error={!!errors.repeatPassword}
              helperText={
                errors.repeatPassword ? errors.repeatPassword.message : ""
              }
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Reset password
            </Button>
            <Grid container>
              <Grid item>
                <Link href="/register" variant="body2">
                  Don&apos;t have an account? Register
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <CopyrightLine />
            </Box>
          </form>
        </div>
      </Grid>
    </PageLayout>
  );
};

export default ResetPasswordPage;
