import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import makeStyles from "@mui/styles/makeStyles";
import { toast } from "react-toastify";
import StatusCodes from "http-status-codes";

import CopyrightLine from "../components/CopyrightLine";
import BorrowerService from "../../services/BorrowerService";
import PageLayout from "../components/PageLayout";
import {
  usePostVerifyEmailCodeMutation,
  usePostCheckEmailCodeMutation,
} from "../../borrower/borrowerApi";

import RegistrationSplash from "../../static/registration.jpg";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../utils/regexValidations";

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: `url(${RegistrationSplash})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  adornedButton: {
    width: "40%",
  },
}));

/* eslint-disable react/jsx-props-no-spreading */
const RegisterPage = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isVerified, setIsVerified] = useState(false);
  const [postVerifyEmailCode] = usePostVerifyEmailCodeMutation();
  const [postCheckEmailCode] = usePostCheckEmailCodeMutation();
  const {
    getValues,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  const handleSendVerifyCode = () => {
    const email = getValues("email");
    postVerifyEmailCode({
      email,
    })
      .unwrap()
      .then(() => {
        toast.success("Verification email sent.", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch(() => {
        toast.error("Something went wrong.", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  const handleCheckVerifyCode = () => {
    const email = getValues("email");
    const verifycode = getValues("verifyEmailCode");
    postCheckEmailCode({
      email,
      verifycode,
    })
      .unwrap()
      .then(() => {
        setIsVerified(true);
      })
      .catch(() => {
        toast.error("Invalid code.", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  const onSubmit = (data) => {
    BorrowerService.register({
      ...data,
      sbaLoanNumber: data.sbaNumber,
      SSN: data.taxId,
      username: data.email,
    })
      .then(() => {
        toast.success("Registration is successful.", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        navigate("/");
      })
      .catch((error) => {
        const errorStatus = error.response.status;
        let errorMsg = "Error at registration.";
        if (errorStatus === StatusCodes.BAD_REQUEST) {
          errorMsg = "Check your requests.";
        } else if (errorStatus === StatusCodes.NOT_FOUND) {
          errorMsg = "Registration cannot be verified.";
        } else if (errorStatus === StatusCodes.CONFLICT) {
          errorMsg = "Account already exists.";
        }
        toast.error(errorMsg, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  return (
    <PageLayout>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Typography align="left">Identity Info</Typography>
            <TextField
              {...register("taxId", {
                required: true,
                // TODO: regex not used for customer ease of use, should revisit later
                // pattern: {
                //   value: TAXID_REGEX,
                //   message:
                //     "Does not match Tax ID format: SSN (012-12-1234) or EIN (12-1234567)",
                // },
              })}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="taxId"
              label="Tax ID"
              autoComplete="taxId"
              autoFocus
              error={!!errors.taxId}
              helperText={
                errors.taxId
                  ? errors.taxId.message
                  : "SSN (012-12-1234) or EIN (12-1234567)"
              }
            />
            <TextField
              {...register("sbaNumber", {
                required: true,
              })}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="sbaNumber"
              label="SBA Number"
              autoComplete="sbaNumber"
              error={!!errors.sbaNumber}
              helperText={errors.sbaNumber ? errors.sbaNumber.message : ""}
            />
            <br />
            <br />
            <Typography align="left">Account Info</Typography>
            <Typography align="left" variant="body2">
              {isVerified
                ? "Thanks for verifying your email address."
                : "We require you to verify your email address before registering."}
            </Typography>
            <TextField
              {...register("email", {
                required: true,
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Does not match email format",
                },
              })}
              disabled={isVerified}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              autoComplete="email"
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : ""}
              InputProps={{
                endAdornment: (
                  <Button
                    disabled={isVerified}
                    className={classes.adornedButton}
                    onClick={handleSendVerifyCode}
                  >
                    Send Code
                  </Button>
                ),
              }}
            />
            <TextField
              {...register("verifyEmailCode")}
              disabled={isVerified}
              variant="outlined"
              margin="normal"
              fullWidth
              id="verifyEmailCode"
              label="Verify Email Code"
              InputProps={{
                endAdornment: (
                  <Button
                    disabled={isVerified}
                    className={classes.adornedButton}
                    onClick={handleCheckVerifyCode}
                  >
                    Verify
                  </Button>
                ),
              }}
            />
            <TextField
              {...register("password", {
                required: true,
                pattern: {
                  value: PASSWORD_REGEX,
                  message:
                    "At least 8 characters including at least 1 uppercase, 1 lowercase, 1 number and 1 special character ! @ # $ % ^ & * ( ) _ + - = { } | ~ . , ; ? /",
                },
              })}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              error={!!errors.password}
              helperText={
                errors.password
                  ? errors.password.message
                  : "At least 8 characters including at least 1 uppercase, 1 lowercase, 1 number and 1 special character ! @ # $ % ^ & * ( ) _ + - = { } | ~ . , ; ? /"
              }
            />
            <TextField
              {...register("repeatPassword", {
                validate: (value) =>
                  value === password.current || "The passwords do not match",
              })}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="repeatPassword"
              name="repeatPassword"
              label="Repeat Password"
              type="password"
              error={!!errors.repeatPassword}
              helperText={
                errors.repeatPassword ? errors.repeatPassword.message : ""
              }
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!isVerified}
            >
              Register
            </Button>
            <Grid container>
              <Grid item xs />
              <Grid item>
                <Link href="/" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <CopyrightLine />
            </Box>
          </form>
        </div>
      </Grid>
    </PageLayout>
  );
};

export default RegisterPage;
