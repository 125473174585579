import AuthService from "./AuthService";

export const getAuthenHeaderConfig = () => {
  const jwt = AuthService.geJwt();
  const config = {
    headers: {
      Authorization: jwt,
    },
  };
  return config;
};

export const getAuthAndContentHeadersConfig = () => {
  const jwt = AuthService.geJwt();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: jwt,
    },
  };
  return config;
};

export const getAuthHeaderBlobResponseTypeAndParamsConfig = (myParams) => {
  const jwt = AuthService.geJwt();
  const config = {
    headers: {
      Authorization: jwt,
    },
    responseType: "blob",
    params: myParams,
  };
  return config;
};
