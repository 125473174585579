import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist-indexeddb-storage";

import rootReducer from "./rootReducer";
import { borrowerApi } from "../borrower/borrowerApi";

// Rehydrate state on App start. It does not mean a way to initialize your App state.
// (See Dan Abramov comment at https://stackoverflow.com/questions/36619093/why-do-i-get-reducer-returned-undefined-during-initialization-despite-pr/36620420#36620420)
const initialState = window.INITIAL_REDUX_STATE;

const persistConfig = {
  key: "root",
  storage: storage("PPP"),
  // ignore RTK query reducers for persistence
  // each new RTK query API should be added here!
  blacklist: [borrowerApi.reducerPath],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(borrowerApi.middleware),
  preloadedState: initialState,
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
