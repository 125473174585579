import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import CopyrightLine from "../components/CopyrightLine";
import PageLayout from "../components/PageLayout";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  heading: {
    fontWeight: "600",
    margin: theme.spacing(2, 0),
  },
  button: {
    display: "block",
    margin: "20px auto",
  },
  main: {
    margin: "0 auto",
    width: "50%",
  },
  p: {
    margin: theme.spacing(2, 0),
  },
  paper: {
    margin: theme.spacing(8, 4),
    padding: theme.spacing(4, 4),
  },
}));

const VerifyEmailPage = () => {
  const classes = useStyles();

  return (
    <PageLayout>
      <Grid item xs={12}>
        <Box className={classes.main}>
          <Paper className={classes.paper} elevation={6}>
            <Typography className={classes.heading} component="h1" variant="h5">
              Please verify your email
            </Typography>
            <Typography className={classes.p} component="p" variant="body">
              You&apos;re almost there! We sent an email to
            </Typography>
            <Typography className={classes.p} component="p" variant="body">
              Just click on the link in that email to verify your email. If you
              don&apos;t see it, you may need to check your spam folder.
            </Typography>
            <Typography className={classes.p} component="p" variant="body">
              Still can&apos;t find the email?
            </Typography>
            <Button
              className={classes.button}
              type="submit"
              variant="contained"
              color="primary"
            >
              Resend email
            </Button>
            <Link href="/help" variant="body2">
              Need help? Contact Us
            </Link>
            <Box mt={5}>
              <CopyrightLine />
            </Box>
          </Paper>
        </Box>
      </Grid>
    </PageLayout>
  );
};

export default VerifyEmailPage;
