import { useState, useRef } from "react";
import { Routes, Navigate, Route, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import CssBaseline from "@mui/material/CssBaseline";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";

import LoginPage from "./LoginPage";
import RegisterPage from "./RegisterPage";
import DashboardPage from "../../dashboard/containers/DashboardPage";
import ProfilePage from "../../profile/containers/ProfilePage";
import StatementsPage from "../../dashboard/containers/StatementsPage";
import SessionTimeoutDialog from "../components/SessionTimeoutDialog";
import { logout } from "../../auth/authActions";
import ForgotPasswordPage from "./ForgotPasswordPage";
import ResetPasswordPage from "./ResetPasswordPage";
import AddAutopayPage from "../../autopay/containers/AddAutopayPage";
import ManageAutopayPage from "../../autopay/containers/ManageAutopayPage";
import HelpPage from "../../help/containers/HelpPage";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { isAuthed } from "../../auth/authSelectors";
import NoticesPage from "../../dashboard/containers/NoticesPage";
import { useCheckJwtExpiryEffect } from "../../auth/hooks/useCheckJwtExpiryEffect";
import VerifyEmailPage from "./VerifyEmailPage";

const theme = createTheme();

function App() {
  const countDownFrom = 30;

  useCheckJwtExpiryEffect();

  const isAuthenticated = useSelector(isAuthed);
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(countDownFrom);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sessionTimeoutRef = useRef(null);
  const countdownIntervalRef = useRef(null);

  const handleContinue = () => {
    setTimeoutModalOpen(false);
    clearTimeout(sessionTimeoutRef.current);
    clearInterval(countdownIntervalRef.current);
    setTimeoutCountdown(countDownFrom);
  };

  const handleLogout = () => {
    setTimeoutModalOpen(false);
    clearTimeout(sessionTimeoutRef.current);
    clearInterval(countdownIntervalRef.current);
    setTimeoutCountdown(countDownFrom);

    dispatch(logout());
    navigate("/");
  };

  const handleSessionTimeout = () => {
    let countDown = countDownFrom;
    countdownIntervalRef.current = setInterval(() => {
      if (countDown > 0) {
        countDown -= 1;
        setTimeoutCountdown(countDown);
      } else {
        handleLogout();
      }
    }, 1000);
  };

  const handleOnIdle = () => {
    if (isAuthenticated && !timeoutModalOpen) {
      setTimeoutModalOpen(true);
      sessionTimeoutRef.current = setTimeout(handleSessionTimeout(), 5000); // delay 5 sec to handleSessionTimeout
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15, // 15 min
    onIdle: handleOnIdle,
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className="App">
          <CssBaseline />
          <Routes>
            <Route exact path="/" element={<LoginPage />} />
            <Route path="/help" element={<HelpPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/forgot" element={<ForgotPasswordPage />} />
            <Route path="/resetPassword" element={<ResetPasswordPage />} />
            <Route path="/verifyEmail" element={<VerifyEmailPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route
              path="/statements/:loanId/:sbaLoanNumber"
              element={<StatementsPage />}
            />
            <Route
              path="/notices/:loanId/:sbaLoanNumber"
              element={<NoticesPage />}
            />
            <Route
              path="/statements"
              render={() => <Navigate to="/dashboard" />}
            />
            <Route path="/profile" element={<ProfilePage />} />
            <Route
              path="/autopay/:loanId/:sbaLoanNumber/add"
              element={<AddAutopayPage />}
            />
            <Route
              path="/autopay/:loanId/:sbaLoanNumber/edit"
              element={<AddAutopayPage isEdit />}
            />
            <Route
              path="/autopay/:loanId/:sbaLoanNumber"
              element={<ManageAutopayPage />}
            />
          </Routes>

          <SessionTimeoutDialog
            open={timeoutModalOpen && isAuthenticated}
            countdown={timeoutCountdown}
            handleLogout={handleLogout}
            handleContinue={handleContinue}
          />
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
