import { SERVER_URL, AUTH_JWT } from "../constants/appConstants";

const AuthService = {
  authenticateAsync: async (user) => {
    try {
      const response = await fetch(`${SERVER_URL}/login`, {
        method: "POST",
        body: JSON.stringify(user),
      });
      const jwtToken = response.headers.get("Authorization");
      if (jwtToken !== null) {
        // we only store jwtToken in the sessionStorge
        sessionStorage.setItem(AUTH_JWT, jwtToken);
      }
      return jwtToken;
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      throw e;
    }
  },

  // we only store jwtToken in the sessionStorge
  geJwt: () => sessionStorage.getItem(AUTH_JWT),

  unauthenticate: () => sessionStorage.removeItem(AUTH_JWT),
};

export default AuthService;
