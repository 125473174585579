import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import makeStyles from "@mui/styles/makeStyles";
import { toast } from "react-toastify";

import CopyrightLine from "../components/CopyrightLine";
import PageLayout from "../components/PageLayout";
import { login } from "../../auth/authActions";

import LoginSplash from "../../static/login.jpg";
import { EMAIL_REGEX } from "../../utils/regexValidations";
import { isAuthed } from "../../auth/authSelectors";

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: `url(${LoginSplash})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

/* eslint-disable react/jsx-props-no-spreading */
const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const isAuthenticated = useSelector(isAuthed);

  useEffect(() => {
    // only run once on load and check if already authenticated
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  });

  const handleLogin = async (data) => {
    setLoading(true);
    const user = { username: data.email, password: data.password };
    try {
      const authenticated = await dispatch(login(user));
      setLoading(false);
      if (authenticated) {
        navigate("/dashboard");
      } else {
        toast.error("Check your username (email) and password", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (e) {
      setLoading(false);
      toast.error("Unexpected login error. Contact admin", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  return (
    <PageLayout>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(handleLogin)}
          >
            <TextField
              {...register("email", {
                required: "Email required",
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Does not match email format",
                },
              })}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              label="Email Address"
              autoComplete="email"
              autoFocus
              error={errors.email}
              helperText={errors.email ? errors.email.message : ""}
            />
            <TextField
              {...register("password", {
                required: "Password required",
              })}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              error={errors.password}
              helperText={errors.password ? errors.password.message : ""}
            />
            <FormControlLabel
              control={
                <Checkbox
                  {...register("remember")}
                  name="remember"
                  color="primary"
                  defaultValue={false}
                />
              }
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign in
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forgot" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/register" variant="body2">
                  Don&apos;t have an account? Register
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <CopyrightLine />
            </Box>
          </form>
        </div>
      </Grid>
      {loading && <CircularProgress size={68} />}
    </PageLayout>
  );
};

export default LoginPage;
