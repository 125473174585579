import PropTypes from "prop-types";
import { Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  headingStack: {
    color: "#1769aa",
    margin: "10px 0",
  },
  sectionDiv: {
    display: "block",
    margin: "20px 0",
  },
  sectionHeading: {
    display: "block",
    fontSize: "14pt",
    fontWeight: 500,
    textAlign: "left",
  },
});

const ProfilePageSection = ({ children, leftMedia, title }) => {
  const classes = useStyles();

  return (
    <div className={classes.sectionDiv}>
      <Stack className={classes.headingStack} spacing={2} direction="row">
        {leftMedia}
        <Typography className={classes.sectionHeading} variant="h3">
          {title}
        </Typography>
      </Stack>
      {children}
    </div>
  );
};

ProfilePageSection.propTypes = {
  children: PropTypes.node,
  leftMedia: PropTypes.node,
  title: PropTypes.string,
};

export default ProfilePageSection;
