import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppBar, Toolbar, Typography, Button, Stack } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { logout } from "../../auth/authActions";
import logoImage from "../../static/ALC_thumbnail.jpg";

export const HEADER_HEIGHT = 64;

const Header = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthed);
  const myUserName = useSelector((state) => state.auth.username);
  const myUserRole = useSelector((state) => state.auth.role);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const navigate = useNavigate();

  const useStyles = makeStyles(() => ({
    header: {
      backgroundColor: "#fff",
      height: HEADER_HEIGHT,
    },
    headerLogo: {
      display: "inline-block",
      height: 54,
      padding: 5,
    },
    logoText: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 600,
      color: "#333",
      textAlign: "left",
    },
    menuButton: {
      color: "#333",
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 700,
      fontSize: "1em",
      marginLeft: "38px",
      textTransform: "none",
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
    },
  }));
  const { header, headerLogo, logoText, menuButton, toolbar } = useStyles();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const hasAdminRole = myUserRole === "ADMIN";
  const toobarVariant = isAuthenticated ? "dense" : "regular";
  return (
    <header>
      <AppBar className={header}>
        <Toolbar className={toolbar} variant={toobarVariant}>
          <Stack alignItems="center" direction="row">
            <RouterLink to="/">
              <img
                className={headerLogo}
                src={logoImage}
                alt="ALC PPP Loan Service"
              />
            </RouterLink>
            <Typography variant="h6" component="h1" className={logoText}>
              ALC Servicing Platform
            </Typography>
          </Stack>
          {isAuthenticated && (
            <div>
              <Button
                color="inherit"
                className={menuButton}
                key="help"
                to="/help"
                component={RouterLink}
              >
                Need help?
              </Button>

              <Button
                color="inherit"
                className={menuButton}
                key="profile"
                to="/profile"
                component={RouterLink}
              >
                Profile &amp; settings
              </Button>

              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>{myUserName}</MenuItem>
                <MenuItem onClick={handleClose}>Role: {myUserRole}</MenuItem>
              </Menu>

              <Button
                color="inherit"
                className={menuButton}
                key="logout"
                onClick={handleLogout}
              >
                Log out
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </header>
  );
};

export default Header;
