import {
  SET_AUTH,
  SET_JWT,
  SET_USERNAME,
  SET_USERROLE,
} from "./authActionTypes";

const initialState = {
  isAuthed: false,
  jwt: "",
  username: "",
  role: "",
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_AUTH:
      return { ...state, isAuthed: payload };

    case SET_JWT:
      return { ...state, jwt: payload };

    case SET_USERNAME:
      return { ...state, username: payload };

    case SET_USERROLE:
      return { ...state, role: payload };

    default: {
      return state;
    }
  }
};

export default authReducer;
