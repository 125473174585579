import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import {
  Box,
  Divider,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Stack from "@mui/material/Stack";

import PageLayout from "../../app/components/PageLayout";
import DashboardTabHeader from "../components/DashboardTabHeader";
import { authSelector } from "../../auth/authSelectors";
import {
  useGetBorrowerOverviewQuery,
  useGetLoanDetailsQuery,
  useGetPaymentHistoryQuery,
} from "../../borrower/borrowerApi";

const PAYMENT_TYPES = {
  EP: "Electronic Payment",
  CHECK: "Check",
  ECK: "Check",
};

const useStyles = makeStyles({
  item: {
    height: "100%",
    backgroundColor: "#eee",
  },
  dashboardCard: {
    margin: "0 10px 10px 10px",
  },
  loanCard: {},
  selectedLoanCard: {
    backgroundColor: "#e0f1ff",
  },
  loanSummaryCard: {},
  loanHistoryCard: { marginTop: "10px", marginBottom: "10px" },
  heading: {
    display: "block",
    fontSize: "20pt",
    margin: "20px 0",
  },
  loanDetails: {
    fontWeight: 500,
    textTransform: "uppercase",
  },
  loanAmount: { fontWeight: 500, color: "#2b50aa" },
  loanHeader: { fontSize: "0.8em" },
  loanNumber: { fontSize: "0.9em", fontWeight: 500 },
  manageLoanStack: {
    marginTop: "20px",
  },
  cellBold: {
    fontWeight: 500,
  },
  linkButton: {
    textTransform: "none",
  },
  clickableCard: {
    cursor: "pointer",
  },
  name: {
    fontWeight: 500,
  },
  mediumHeading: {
    margin: "5px auto",
    fontWeight: 500,
    fontSize: "0.9em",
    color: "#666",
  },
  smallHeading: {
    margin: "5px auto",
    fontWeight: 500,
    fontSize: "0.8em",
    color: "#666",
  },
  smallData: {
    fontSize: "0.8em",
    color: "#999",
  },
  margins: {
    margin: "10px",
  },
  paidCell: {
    color: "#008000",
    fontWeight: 500,
  },
  transactionHistoryTitle: {
    textAlign: "left",
    fontWeight: 500,
    fontSize: "1.2em",
  },
});

const LoanTabPanel = ({ children, value, index }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && <div>{children}</div>}
  </div>
);

LoanTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const DashboardPage = () => {
  const classes = useStyles();
  const { username } = useSelector(authSelector);
  const [selectedLoan, setSelectedLoan] = useState(0);
  const [loanTab, setLoanTab] = useState(0);
  const {
    data: loans,
    error,
    isLoading,
  } = useGetBorrowerOverviewQuery(username);
  const { data: loanDetails } = useGetLoanDetailsQuery(
    loans && loans[selectedLoan].loanId,
    {
      skip: !loans,
    }
  );
  const { data: loanHistory } = useGetPaymentHistoryQuery(
    loans && loans[selectedLoan].loanId,
    {
      skip: !loans,
    }
  );

  useEffect(() => {
    if (error && error.message) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  }, [error]);

  if (isLoading) {
    return <CircularProgress />;
  }

  const handleSelectLoan = (index) => () => {
    setSelectedLoan(index);
  };

  const renderLoans = () =>
    loans &&
    loans.map((loan, index) => {
      const { loanId, principalBalance, sbaLoanNumber } = loan;
      return (
        <Card
          key={loanId}
          className={`${classes.dashboardCard} ${
            index === selectedLoan ? classes.selectedLoanCard : classes.loanCard
          }`}
        >
          <CardContent
            className={classes.clickableCard}
            onClick={handleSelectLoan(index)}
          >
            <Grid container alignItems="center" justify="center">
              <Grid item xs={6}>
                <Typography className={classes.loanHeader}>
                  SBA PPP Loan
                </Typography>
                <Typography className={classes.loanNumber}>
                  {sbaLoanNumber}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.loanAmount}>
                  ${principalBalance.toLocaleString()}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
    });

  const renderLoanSummary = () => {
    if (!loans || loans.length === 0 || !loanDetails) {
      return null;
    }

    const { dueDate, paymentDue, principalBalance } = loans[selectedLoan];
    const {
      alcLoanNumber,
      interestRate,
      currentBalance,
      loanAmount,
      disbursementDate,
      borrowerName,
      taxId,
    } = loanDetails;
    const dt = dueDate ? DateTime.fromISO(dueDate) : "Not Available";
    const disbursementDt = disbursementDate
      ? DateTime.fromISO(disbursementDate)
      : "Not Available";

    return (
      <Card className={classes.loanSummaryCard}>
        <CardContent>
          <Tabs
            value={loanTab}
            onChange={(_, newValue) => setLoanTab(newValue)}
          >
            <Tab label="Overview" id="overview" />
            <Tab label="Loan details" id="details" />
          </Tabs>
          <LoanTabPanel value={loanTab} index={0}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Current balance</TableCell>
                    <TableCell align="right">
                      <Typography className={classes.cellBold}>
                        ${principalBalance.toLocaleString()}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Payment due date</TableCell>
                    <TableCell align="right">
                      <Typography className={classes.cellBold}>
                        {dt.toLocaleString({
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Payment due</TableCell>
                    <TableCell align="right">
                      <Typography className={classes.cellBold}>
                        ${paymentDue.toLocaleString()}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Stack
              className={classes.manageLoanStack}
              spacing={2}
              justifyContent="flex-end"
              direction="row"
            >
              <Button
                to={`/autopay/${loans[selectedLoan].loanId}/${loans[selectedLoan].sbaLoanNumber}`}
                component={RouterLink}
                variant="contained"
              >
                Manage Autopay
              </Button>
            </Stack>
          </LoanTabPanel>
          <LoanTabPanel value={loanTab} index={1}>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              <Box
                sx={{
                  margin: "20px",
                  textAlign: "left",
                  minWidth: "30%",
                }}
              >
                <Typography className={classes.name}>{borrowerName}</Typography>
                <Typography className={classes.smallHeading}>Tax ID</Typography>
                <Typography className={classes.smallData}>
                  {taxId.padStart(9, "x")}
                </Typography>
                <Typography className={classes.smallHeading}>
                  ALC Loan Number
                </Typography>
                <Typography className={classes.smallData}>
                  {alcLoanNumber}
                </Typography>
                <Typography className={classes.smallHeading}>
                  Loan Amount
                </Typography>
                <Typography className={classes.smallData}>
                  ${loanAmount.toLocaleString()}
                </Typography>
                <Typography className={classes.smallHeading}>
                  Loan Disbursement Date
                </Typography>
                <Typography className={classes.smallData}>
                  {disbursementDt.toLocaleString({
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </Typography>
              </Box>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography className={classes.loanDetails}>
                          Loan Details
                        </Typography>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                    <TableRow>
                      <TableCell>Current Balance</TableCell>
                      <TableCell align="right">
                        <Typography className={classes.cellBold}>
                          ${currentBalance.toLocaleString()}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Interest Rate</TableCell>
                      <TableCell align="right">
                        <Typography className={classes.cellBold}>
                          {interestRate}%
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Payment Due Date</TableCell>
                      <TableCell align="right">
                        <Typography className={classes.cellBold}>
                          {dt.toLocaleString({
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <Stack
                  className={classes.manageLoanStack}
                  spacing={2}
                  justifyContent="flex-end"
                  direction="row"
                >
                  <Button
                    to={`/autopay/${loans[selectedLoan].loanId}/${loans[selectedLoan].sbaLoanNumber}`}
                    component={RouterLink}
                    variant="contained"
                  >
                    Manage Autopay
                  </Button>
                </Stack>
              </TableContainer>
            </Stack>
          </LoanTabPanel>
        </CardContent>
      </Card>
    );
  };

  const renderLoanHistory = () =>
    loanTab === 1 && (
      <Card className={classes.loanHistoryCard}>
        <CardContent>
          <Typography className={classes.transactionHistoryTitle}>
            Transaction History
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              {loanHistory && loanHistory.length > 0 && (
                <TableBody>
                  {loanHistory
                    .slice()
                    .reverse()
                    .map((loanHistoryEntry) => {
                      const { batchId, paymentDate, paymentType, amount } =
                        loanHistoryEntry;
                      const dt = paymentDate
                        ? DateTime.fromISO(paymentDate)
                        : "Not Available";
                      return (
                        <TableRow key={batchId}>
                          <TableCell>
                            {dt.toLocaleString({
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                          </TableCell>
                          <TableCell>
                            {PAYMENT_TYPES[paymentType] || "unknown"}
                          </TableCell>
                          <TableCell className={classes.paidCell}>
                            +${amount}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {!loanHistory && (
            <Typography className={classes.margins} variant="h6">
              No data to show.
            </Typography>
          )}
        </CardContent>
      </Card>
    );

  const renderQuickTasks = () => {
    const disabled = !loans || !loans.length;
    return (
      <Card className={classes.dashboardCard}>
        <CardContent>
          <Button
            className={classes.linkButton}
            color="inherit"
            disabled={disabled}
            to={
              disabled
                ? ""
                : `/statements/${loans[selectedLoan].loanId}/${loans[selectedLoan].sbaLoanNumber}`
            }
            component={RouterLink}
          >
            View Statements
          </Button>
        </CardContent>
      </Card>
    );
  };

  return (
    <PageLayout>
      <DashboardTabHeader />
      <Grid className={classes.item} item xs={3}>
        <Typography className={classes.heading} variant="h2">
          Loans
        </Typography>
        {renderLoans()}
      </Grid>
      <Grid className={classes.item} item xs={6}>
        <Typography className={classes.heading} variant="h2">
          Loan Summary
        </Typography>
        {renderLoanSummary()}
        {renderLoanHistory()}
      </Grid>
      <Grid className={classes.item} item xs={3}>
        <Typography className={classes.heading} variant="h2">
          Quick Tasks
        </Typography>
        {renderQuickTasks()}
      </Grid>
    </PageLayout>
  );
};

export default DashboardPage;
