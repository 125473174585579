import React from "react";
import * as PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Slide,
} from "@mui/material";
import clsx from "clsx";
import red from "@mui/material/colors/red";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  dialog: {
    borderRadius: 0,
  },
  button: {
    borderRadius: 0,
    textTransform: "none",
    padding: 5,
  },
  logout: {
    color: "#fff",
    backgroundColor: red[500],
    "&amp;:hover": {
      backgroundColor: red[700],
    },
  },
  countdown: {
    color: red[700],
  },
}));

/* eslint-disable react/jsx-props-no-spreading */
const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function SessionTimeoutDialog({
  open,
  countdown,
  handleLogout,
  handleContinue,
}) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      aria-labelledby="session-timeout-dialog"
      aria-describedby="session-timeout-dialog"
      classes={{ paper: classes.dialog }}
      TransitionComponent={Transition}
    >
      <DialogTitle id="session-timeout-dialog-title">
        Session Timeout
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          The current session is about to expire in{" "}
          <span className={classes.countdown}>{countdown}</span> seconds.
        </Typography>
        <Typography variant="body2">
          Would you like to continue the session?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleLogout}
          variant="contained"
          className={clsx(classes.logout, classes.button)}
        >
          Logout
        </Button>
        <Button
          onClick={handleContinue}
          color="primary"
          variant="contained"
          className={classes.button}
        >
          Continue Session
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SessionTimeoutDialog.propTypes = {
  /**
   * indicator whether the dialog is open/close
   */
  open: PropTypes.bool.isRequired,
  /**
   * the countdown timer.
   */
  countdown: PropTypes.number.isRequired,
  /**
   * callback function to handle closing action
   */
  handleLogout: PropTypes.func.isRequired,
  /**
   * callback function to handle confirm action.
   */
  handleContinue: PropTypes.func.isRequired,
};
