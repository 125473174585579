import { useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Switch,
  Typography,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";
import LockIcon from "@mui/icons-material/Lock";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";

import DashboardTabHeader from "../../dashboard/components/DashboardTabHeader";
import PageLayout from "../../app/components/PageLayout";
import { authSelector } from "../../auth/authSelectors";
import ProfilePageSection from "../components/ProfilePageSection";
import {
  useGetProfileQuery,
  usePutProfileMutation,
} from "../../borrower/borrowerApi";
import EditProfileDialog from "../components/EditProfileDialog";

const useStyles = makeStyles({
  heading: {
    display: "block",
    fontSize: "20pt",
    margin: "20px 0",
    textAlign: "left",
  },
  headingItem: {
    display: "block",
    margin: "10px 5%",
  },
  item: {
    height: "100%",
  },
  settingsCard: {
    margin: "0 10% 10px 10%",
    textAlign: "left",
  },
  cardHeading: {
    fontSize: "16pt",
    textAlign: "left",
    margin: "10px 0",
  },
  sectionHeading: {
    fontSize: "14pt",
    fontWeight: 500,
    textAlign: "left",
  },
  divider: {
    margin: "10px auto",
  },
  cardKey: {
    fontWeight: 500,
    marginRight: "7px",
  },
  button: {
    marginTop: "10px",
  },
});

const LoanTabPanel = ({ children, value, index }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && <div>{children}</div>}
  </div>
);

LoanTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ProfilePage = () => {
  const classes = useStyles();
  const { username } = useSelector(authSelector);
  const [saveModalValue, setSaveModalValue] = useState(null);
  const [saveModalTitle, setSaveModalTitle] = useState(null);
  const { data: profileData, isLoading } = useGetProfileQuery(username);
  const [putProfileMethod] = usePutProfileMutation();

  if (isLoading) {
    return null;
  }

  const {
    username: id,
    email,
    secondEmail,
    paperless,
    phone,
    streetAddress,
    city,
    state,
    zip,
  } = profileData;

  const formattedCityAddress = `${city ? `${city},` : ""} ${state || ""} ${
    zip || ""
  }`;

  const editSettingsValue = (settingsValue, settingsTitle) => () => {
    setSaveModalValue(settingsValue);
    setSaveModalTitle(settingsTitle);
  };

  const handleCancel = () => {
    setSaveModalValue(null);
  };

  const handleChangePaperless = (evt) => {
    const { ...rest } = profileData;
    putProfileMethod({ ...rest, paperless: evt.target.checked })
      .unwrap()
      .catch(() => {
        toast.error("Something went wrong.", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  return (
    <PageLayout>
      <EditProfileDialog
        onClose={handleCancel}
        isOpen={!!saveModalValue}
        title={saveModalTitle}
        settingsValue={saveModalValue}
      />
      <DashboardTabHeader />
      <Grid className={classes.headingItem} item xs={12}>
        <Typography className={classes.heading} variant="h2">
          Profile &amp; Settings
        </Typography>
      </Grid>
      <Grid className={classes.item} item xs={6}>
        <Card className={classes.settingsCard}>
          <CardContent>
            <Typography className={classes.cardHeading} variant="h2">
              Contact Information
            </Typography>
            <ProfilePageSection
              leftMedia={<PhoneAndroidIcon />}
              title="Phone Number"
            >
              <Typography className={classes.cardKey} variant="body">
                Primary Number:
              </Typography>
              {phone}
              <br />
              <Button
                className={classes.button}
                variant="contained"
                onClick={editSettingsValue("phone", "Phone Number")}
              >
                Edit
              </Button>
            </ProfilePageSection>
            <Divider />
            <ProfilePageSection leftMedia={<EmailIcon />} title="Primary Email">
              {email}
              <br />
              <Button
                className={classes.button}
                variant="contained"
                onClick={editSettingsValue("email", "Email Address")}
              >
                Edit
              </Button>
            </ProfilePageSection>
            <ProfilePageSection
              leftMedia={<EmailIcon />}
              title="Secondary Email"
            >
              {secondEmail}
              <br />
              <Button
                className={classes.button}
                variant="contained"
                onClick={editSettingsValue("secondEmail", "Email Address")}
              >
                Edit
              </Button>
              <br />
              <br />
              <Typography>Paperless Preference</Typography>
              <Switch
                onChange={handleChangePaperless}
                defaultChecked={paperless}
              />
            </ProfilePageSection>
            <Divider />
            <ProfilePageSection leftMedia={<HomeIcon />} title="Address">
              <p>{streetAddress}</p>
              <p>{formattedCityAddress}</p>
              <Button
                className={classes.button}
                variant="contained"
                onClick={editSettingsValue("address", "Address")}
              >
                Edit
              </Button>
            </ProfilePageSection>
          </CardContent>
        </Card>
      </Grid>
      <Grid className={classes.item} item xs={6}>
        <Card className={classes.settingsCard}>
          <CardContent>
            <Typography className={classes.cardHeading} variant="h2">
              Login Preferences
            </Typography>
            <ProfilePageSection leftMedia={<LockIcon />} title="Personal ID">
              <Typography className={classes.cardKey} variant="body">
                Personal ID:
              </Typography>
              {id}
              <Divider className={classes.divider} />
              <Typography className={classes.cardKey} variant="body">
                Password:
              </Typography>
              For security reason we do not display your password here.
              <br />
              <Button
                className={classes.button}
                variant="contained"
                onClick={editSettingsValue("password", "Password")}
              >
                Edit
              </Button>
            </ProfilePageSection>
          </CardContent>
        </Card>
      </Grid>
    </PageLayout>
  );
};

export default ProfilePage;
