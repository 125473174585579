import { combineReducers } from "redux";
import authReducer from "../auth/authReducer";
import { borrowerApi } from "../borrower/borrowerApi";

const rootReducer = combineReducers({
  auth: authReducer,
  [borrowerApi.reducerPath]: borrowerApi.reducer,
});

export default rootReducer;
