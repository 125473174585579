import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import BorrowerService from "../../services/BorrowerService";
import PageLayout from "../../app/components/PageLayout";
import DashboardTabHeader from "../components/DashboardTabHeader";
import StatementsSidebar from "../components/StatementsSidebar";

const useStyles = makeStyles({
  item: {
    height: "100%",
    backgroundColor: "#eee",
  },
  dashboardCard: {
    margin: "0 10px 10px 10px",
    textAlign: "left",
  },
  loanSummaryCard: { marginRight: "10px", textAlign: "left" },
  heading: {
    textAlign: "left",
    display: "block",
    fontSize: "20pt",
    margin: "20px 0",
    paddingLeft: "10px",
  },
  loanAmount: { fontWeight: 500, color: "#2b50aa" },
  loanHeader: { fontSize: "0.8em" },
  loanNumber: { fontWeight: 500 },
  manageLoanStack: {
    marginTop: "20px",
  },
  cellBold: {
    fontWeight: 500,
  },
  linkButton: {
    textTransform: "none",
  },
  statementDownloadButton: {
    display: "flex",
    marginTop: "10px",
  },
});

const StatementsPage = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [statements, setStatements] = useState([]);
  const { loanId, sbaLoanNumber } = useParams();

  const fetchStatements = useCallback(() => {
    setLoading(true);
    BorrowerService.getStatements({ loanId })
      .then((res) => {
        setLoading(false);
        setStatements(res.data);
      })
      .catch(() => {
        setLoading(false);
        toast.error("Error when fetching loan statements", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  }, [loanId, setStatements]);

  useEffect(() => {
    fetchStatements();
  }, [fetchStatements]);

  if (loading) {
    return <CircularProgress />;
  }

  const downLoadStatementPDF = (s3Key) => {
    setLoading(true);
    BorrowerService.getStatementPDF({ s3Key })
      .then((response) => {
        setLoading(false);
        const url = window.URL.createObjectURL(response.data);
        const a = document.createElement("a");
        a.href = url;
        a.target = "_blank";
        a.click();
      })
      .catch(() => {
        setLoading(false);
        toast.error("Error on downloading statement", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  const renderStatements = () => (
    <Card className={classes.loanSummaryCard}>
      <CardContent>
        <Typography variant="h4" style={{ margin: "10px auto" }}>
          Loans
        </Typography>
        <Typography style={{ margin: "10px auto" }}>
          View or print your Online Statements below.
        </Typography>
        <Divider style={{ margin: "10px auto" }} />
        <Typography variant="h6">Loan - {sbaLoanNumber}</Typography>
        {statements.map((statement) => {
          const { id, statementDate, s3Key } = statement;
          const dt = DateTime.fromISO(statementDate);
          return (
            <Button
              className={classes.statementDownloadButton}
              key={id}
              onClick={() => {
                downLoadStatementPDF(s3Key);
              }}
              startIcon={<PictureAsPdfIcon />}
            >
              <Typography>
                {dt.toLocaleString({
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </Typography>
            </Button>
          );
        })}
      </CardContent>
    </Card>
  );

  return (
    <PageLayout>
      <DashboardTabHeader />
      <Grid className={classes.item} item xs={4}>
        <Typography className={classes.heading} variant="h2">
          I&apos;d like to...
        </Typography>
        <StatementsSidebar />
      </Grid>
      <Grid className={classes.item} item xs={8}>
        <Typography className={classes.heading} variant="h2">
          Statements
        </Typography>
        {renderStatements()}
      </Grid>
    </PageLayout>
  );
};

export default StatementsPage;
