// We start from simple regex for ssn/ein, not too strict. Improve if necessary
// strict ssn: https://www.geeksforgeeks.org/how-to-validate-ssn-social-security-number-using-regular-expression/
// strict ssn: https://howtodoinjava.com/java/regex/java-regex-validate-social-security-numbers-ssn/
// strict ein: hard to find a good one. IRS changes frequently and it is hard to follow
export const TAXID_REGEX = /^(\d{3}-\d{2}-\d{4}|[1-9]\d?-\d{7})$/;

// https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression
export const EMAIL_REGEX =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

// Although NIST and others are challenging Traditional Password Management
// (See https://securityboulevard.com/2021/03/nist-password-guidelines-2021-challenging-traditional-password-management/),
// we are still using length + complexity policy aligned with current aws and
// other banks current.
// Although length is more important than mix, 8-char password is
// not secured unless extending to at least 20 chars(not user-friendly).
// Password such as 123456778 can be cracked within 1 second against
// password dictionary.
export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[!@#$%^&*()_+={}|-~.,;?/])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

export const ROUTING_NUMBER_REGEX = /^[0-9]{9}$/;

// https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number
export const PHONE_NUMBER_REGEX =
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const ZIP_CODE_REGEX = /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/;
