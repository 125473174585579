import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { authSelector } from "../../auth/authSelectors";
import {
  useGetProfileQuery,
  usePutProfileMutation,
  usePutPasswordMutation,
  usePostVerifyEmailCodeMutation,
  usePostCheckEmailCodeMutation,
} from "../../borrower/borrowerApi";
import {
  EMAIL_REGEX,
  PASSWORD_REGEX,
  PHONE_NUMBER_REGEX,
  ZIP_CODE_REGEX,
} from "../../utils/regexValidations";
import { normalizePhoneInput } from "../../utils/normalize";

const useStyles = makeStyles({
  fullwidthInput: {
    width: "100%",
  },
  stack: {
    padding: "20px",
  },
  adornedButton: {
    width: "50%",
  },
});

const EditProfileDialog = ({ onClose, isOpen, title, settingsValue }) => {
  const { username } = useSelector(authSelector);
  const classes = useStyles();
  const { data: profileData } = useGetProfileQuery(username);
  const [putProfileMethod] = usePutProfileMutation();
  const [putPasswordMethod] = usePutPasswordMutation();
  const [postVerifyEmailCode] = usePostVerifyEmailCodeMutation();
  const [postCheckEmailCode] = usePostCheckEmailCodeMutation();
  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm();
  const passwordRef = useRef({});
  passwordRef.current = watch("password", "");

  useEffect(() => {
    // reset form values if re-opening dialog
    if (settingsValue) {
      reset();
    }
  }, [settingsValue, reset]);

  let defaultValues = {};
  if (profileData) {
    defaultValues = profileData;
  }

  const handleSendVerifyCode = (data) => {
    const { email, secondEmail } = data;
    postVerifyEmailCode({
      email: settingsValue === "email" ? email : secondEmail,
    })
      .unwrap()
      .then(() => {
        toast.success("Verification email sent.", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch(() => {
        toast.error("Something went wrong.", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  };

  const handleSave = (data) => {
    const { ...rest } = profileData;
    const {
      oldPassword,
      password,
      repeatPassword,
      email,
      secondEmail,
      verifyEmailCode,
    } = data;
    if (oldPassword && password && repeatPassword) {
      putPasswordMethod({
        email,
        oldPassword,
        password,
      })
        .unwrap()
        .then(() => {
          toast.success("Password updated successfully.", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          onClose();
        })
        .catch(() => {
          toast.error("Something went wrong.", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        });
    } else {
      if (["email", "secondEmail"].includes(settingsValue)) {
        postCheckEmailCode({
          email: settingsValue === "email" ? email : secondEmail,
          verifycode: verifyEmailCode,
        })
          .unwrap()
          .then(() => {
            putProfileMethod({ ...rest, ...data })
              .unwrap()
              .then(() => {
                toast.success("Profile updated successfully.", {
                  position: toast.POSITION.BOTTOM_LEFT,
                });
                onClose();
              })
              .catch(() => {
                toast.error("Something went wrong.", {
                  position: toast.POSITION.BOTTOM_LEFT,
                });
              });
          })
          .catch(() => {
            toast.error("Invalid code.", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          });

        return;
      }

      putProfileMethod({ ...rest, ...data })
        .unwrap()
        .then(() => {
          toast.success("Profile updated successfully.", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          onClose();
        })
        .catch(() => {
          toast.error("Something went wrong.", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        });
    }
  };

  // only register this field if it's the phone number field
  const phoneFormRegister =
    settingsValue === "phone"
      ? register("phone", {
          required: true,
          pattern: {
            value: PHONE_NUMBER_REGEX,
            message: "Does not match phone number format",
          },
        })
      : null;

  const handlePhoneChange = (evt) => {
    setValue("phone", normalizePhoneInput(evt.target.value));
    phoneFormRegister.onChange(evt);
  };

  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle>Edit {title}</DialogTitle>
      <Stack className={classes.stack} spacing={1}>
        <form noValidate>
          {settingsValue === "phone" && (
            <TextField
              {...phoneFormRegister}
              onChange={handlePhoneChange}
              id="phone"
              label="Phone Number"
              variant="standard"
              defaultValue={defaultValues.phone}
              error={!!errors.phone}
              helperText={errors.phone ? errors.phone.message : ""}
            />
          )}
          {settingsValue === "email" && (
            <TextField
              {...register("email", {
                required: true,
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Does not match email format",
                },
              })}
              id="email"
              label="Email"
              variant="standard"
              defaultValue={defaultValues.email}
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : ""}
              InputProps={{
                endAdornment: (
                  <Button
                    className={classes.adornedButton}
                    onClick={handleSubmit(handleSendVerifyCode)}
                  >
                    Send Code
                  </Button>
                ),
              }}
            />
          )}
          {settingsValue === "secondEmail" && (
            <TextField
              {...register("secondEmail", {
                required: true,
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Does not match email format",
                },
              })}
              id="email"
              label="Secondary Email"
              variant="standard"
              defaultValue={defaultValues.secondEmail}
              error={!!errors.secondEmail}
              helperText={errors.secondEmail ? errors.secondEmail.message : ""}
              InputProps={{
                endAdornment: (
                  <Button
                    className={classes.adornedButton}
                    onClick={handleSubmit(handleSendVerifyCode)}
                  >
                    Send Code
                  </Button>
                ),
              }}
            />
          )}
          {["email", "secondEmail"].includes(settingsValue) && (
            <>
              <br />
              <TextField
                className={classes.fullwidthInput}
                {...register("verifyEmailCode")}
                id="verifyEmailCode"
                label="Verify Email Code"
                variant="standard"
              />
            </>
          )}
          {settingsValue === "address" && (
            <Stack className={classes.stack} spacing={1}>
              <TextField
                {...register("streetAddress", {
                  required: true,
                })}
                id="streetAddress"
                label="Street Address"
                variant="standard"
                defaultValue={defaultValues.streetAddress}
                error={errors.streetAddress}
              />
              <TextField
                {...register("city", {
                  required: true,
                })}
                id="city"
                label="City"
                variant="standard"
                defaultValue={defaultValues.city}
                error={errors.city}
              />
              <FormControl variant="standard">
                <InputLabel>State</InputLabel>
                <Controller
                  defaultValue={defaultValues.state}
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <Select {...field} label="State">
                      <MenuItem value="AL">Alabama</MenuItem>
                      <MenuItem value="AK">Alaska</MenuItem>
                      <MenuItem value="AZ">Arizona</MenuItem>
                      <MenuItem value="AR">Arkansas</MenuItem>
                      <MenuItem value="CA">California</MenuItem>
                      <MenuItem value="CO">Colorado</MenuItem>
                      <MenuItem value="CT">Connecticut</MenuItem>
                      <MenuItem value="DE">Delaware</MenuItem>
                      <MenuItem value="DC">District Of Columbia</MenuItem>
                      <MenuItem value="FL">Florida</MenuItem>
                      <MenuItem value="GA">Georgia</MenuItem>
                      <MenuItem value="HI">Hawaii</MenuItem>
                      <MenuItem value="ID">Idaho</MenuItem>
                      <MenuItem value="IL">Illinois</MenuItem>
                      <MenuItem value="IN">Indiana</MenuItem>
                      <MenuItem value="IA">Iowa</MenuItem>
                      <MenuItem value="KS">Kansas</MenuItem>
                      <MenuItem value="KY">Kentucky</MenuItem>
                      <MenuItem value="LA">Louisiana</MenuItem>
                      <MenuItem value="ME">Maine</MenuItem>
                      <MenuItem value="MD">Maryland</MenuItem>
                      <MenuItem value="MA">Massachusetts</MenuItem>
                      <MenuItem value="MI">Michigan</MenuItem>
                      <MenuItem value="MN">Minnesota</MenuItem>
                      <MenuItem value="MS">Mississippi</MenuItem>
                      <MenuItem value="MO">Missouri</MenuItem>
                      <MenuItem value="MT">Montana</MenuItem>
                      <MenuItem value="NE">Nebraska</MenuItem>
                      <MenuItem value="NV">Nevada</MenuItem>
                      <MenuItem value="NH">New Hampshire</MenuItem>
                      <MenuItem value="NJ">New Jersey</MenuItem>
                      <MenuItem value="NM">New Mexico</MenuItem>
                      <MenuItem value="NY">New York</MenuItem>
                      <MenuItem value="NC">North Carolina</MenuItem>
                      <MenuItem value="ND">North Dakota</MenuItem>
                      <MenuItem value="OH">Ohio</MenuItem>
                      <MenuItem value="OK">Oklahoma</MenuItem>
                      <MenuItem value="OR">Oregon</MenuItem>
                      <MenuItem value="PA">Pennsylvania</MenuItem>
                      <MenuItem value="RI">Rhode Island</MenuItem>
                      <MenuItem value="SC">South Carolina</MenuItem>
                      <MenuItem value="SD">South Dakota</MenuItem>
                      <MenuItem value="TN">Tennessee</MenuItem>
                      <MenuItem value="TX">Texas</MenuItem>
                      <MenuItem value="UT">Utah</MenuItem>
                      <MenuItem value="VT">Vermont</MenuItem>
                      <MenuItem value="VA">Virginia</MenuItem>
                      <MenuItem value="WA">Washington</MenuItem>
                      <MenuItem value="WV">West Virginia</MenuItem>
                      <MenuItem value="WI">Wisconsin</MenuItem>
                      <MenuItem value="WY">Wyoming</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
              <TextField
                {...register("zip", {
                  pattern: {
                    value: ZIP_CODE_REGEX,
                    message: "Does not match zip code format",
                  },
                })}
                id="zip"
                label="Zip Code"
                variant="standard"
                defaultValue={defaultValues.zip}
                error={!!errors.zip}
              />
            </Stack>
          )}
          {settingsValue === "password" && (
            <Stack className={classes.stack} spacing={1}>
              <TextField
                {...register("oldPassword", {
                  required: true,
                })}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="oldPassword"
                name="oldPassword"
                label="Current Password"
                type="password"
                autoComplete="current-password"
              />
              <TextField
                {...register("password", {
                  required: true,
                  pattern: {
                    value: PASSWORD_REGEX,
                    message:
                      "At least 8 characters including at least 1 uppercase, 1 lowercase, 1 number and 1 special character ! @ # $ % ^ & * ( ) _ + - = { } | ~ . , ; ? /",
                  },
                })}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password"
                name="password"
                label="Password"
                type="password"
                autoComplete="current-password"
                error={!!errors.password}
                helperText={
                  errors.password
                    ? errors.password.message
                    : "At least 8 characters including at least 1 uppercase, 1 lowercase, 1 number and 1 special character ! @ # $ % ^ & * ( ) _ + - = { } | ~ . , ; ? /"
                }
              />
              <TextField
                {...register("repeatPassword", {
                  validate: (value) =>
                    value === passwordRef.current ||
                    "The passwords do not match",
                })}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="repeatPassword"
                name="repeatPassword"
                label="Repeat Password"
                type="password"
                error={!!errors.repeatPassword}
                helperText={
                  errors.repeatPassword ? errors.repeatPassword.message : ""
                }
              />
            </Stack>
          )}
        </form>
        <Button onClick={handleSubmit(handleSave)}>Save</Button>
        <Button onClick={onClose}>Cancel</Button>
      </Stack>
    </Dialog>
  );
};

EditProfileDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  settingsValue: PropTypes.string,
};

export default EditProfileDialog;
