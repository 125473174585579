import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { jwtSelector } from "../authSelectors";
import { logout } from "../authActions";
import { isJwtExpired } from "../utils/isJwtExpired";

export const useCheckJwtExpiryEffect = () => {
  const dispatch = useDispatch();
  const jwt = useSelector(jwtSelector);
  const navigate = useNavigate();

  useEffect(() => {
    if (jwt.length > 0 && isJwtExpired(jwt)) {
      dispatch(logout());
      navigate("/");
    }
  }, [dispatch, jwt, navigate]);
};
