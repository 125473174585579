import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCookie } from "react-use-cookie";
import { SERVER_URL } from "../constants/appConstants";
import HTTP_METHODS from "../constants/httpMethods";

export const borrowerApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${SERVER_URL}/api`,
    prepareHeaders: (headers, { getState }) => {
      const jwt = getState().auth.jwt;
      const csrfToken = getCookie("XSRF-TOKEN");

      if (jwt) {
        headers.set("authorization", jwt);
      }

      if (csrfToken) {
        headers.set("X-XSRF-TOKEN", csrfToken);
      }

      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["Profile"],
  endpoints: (builder) => ({
    getBorrowerOverview: builder.query({
      query: (username) => `/borrower/dash/${username}`,
    }),
    postForgotPassword: builder.mutation({
      query: (body) => ({
        url: "/borrower/forgot_pw",
        method: HTTP_METHODS.POST,
        body,
      }),
    }),
    postResetPassword: builder.mutation({
      query: (body) => ({
        url: "/borrower/reset_pw",
        method: HTTP_METHODS.POST,
        body,
      }),
    }),
    getPaymentHistory: builder.query({
      query: (loanId) => `/servicingloans/paymentHistory/${loanId}`,
    }),
    getLoanDetails: builder.query({
      query: (loanId) => `/servicingloans/loanDetails/${loanId}`,
    }),
    getProfile: builder.query({
      query: (username) => `/borrower/profile/${username}`,
      providesTags: ["Profile"],
    }),
    putProfile: builder.mutation({
      query: (body) => ({
        url: "/borrower/updateprofile",
        method: HTTP_METHODS.PUT,
        body,
      }),
      invalidatesTags: ["Profile"],
    }),
    putPassword: builder.mutation({
      query: (body) => ({
        url: "/borrower/updatepw",
        method: HTTP_METHODS.PUT,
        body,
      }),
      invalidatesTags: ["Profile"],
    }),
    postVerifyEmailCode: builder.mutation({
      query: (body) => ({
        url: "/borrower/sendVerifycodeEmail",
        method: HTTP_METHODS.POST,
        body,
      }),
    }),
    postCheckEmailCode: builder.mutation({
      query: (body) => ({
        url: "/borrower/checkEmailVerifycode",
        method: HTTP_METHODS.POST,
        body,
      }),
    }),
    getPaymentInfo: builder.query({
      query: (loanId) => `/servicingloans/autopayinfo/${loanId}`,
    }),
    getPaymentMethod: builder.query({
      query: (loanId) => `/servicingloans/paymentmethod/${loanId}`,
    }),
    putDisableAutopay: builder.mutation({
      query: (body) => ({
        url: "/servicingloans/disableAutoPay",
        method: HTTP_METHODS.PUT,
        body,
      }),
    }),
    postPaymentMethod: builder.mutation({
      query: (body) => ({
        url: "/servicingloans/paymentmethod",
        method: HTTP_METHODS.POST,
        body,
      }),
    }),
    putPaymentMethod: builder.mutation({
      query: (body) => ({
        url: "/servicingloans/paymentmethods",
        method: HTTP_METHODS.PUT,
        body,
      }),
    }),
  }),
});

export const {
  useGetBorrowerOverviewQuery,
  usePostForgotPasswordMutation,
  usePostResetPasswordMutation,
  useGetPaymentHistoryQuery,
  useGetLoanDetailsQuery,
  useGetProfileQuery,
  usePutProfileMutation,
  usePutPasswordMutation,
  usePostVerifyEmailCodeMutation,
  usePostCheckEmailCodeMutation,
  useGetPaymentInfoQuery,
  useGetPaymentMethodQuery,
  usePutDisableAutopayMutation,
  usePostPaymentMethodMutation,
  usePutPaymentMethodMutation,
} = borrowerApi;
