import axios from "axios";

import { SERVER_URL } from "../constants/appConstants";
import {
  getAuthenHeaderConfig,
  getAuthHeaderBlobResponseTypeAndParamsConfig,
} from "./ServiceUtil";

const BORROWER_API_BASE_URL = `${SERVER_URL}/api/borrower`;
const SVCING_LOANS_API_BASE_URL = `${SERVER_URL}/api/servicingloans`;

// TODO: POST method, get xsrf token and use it in api call to back end
// See https://www.npmjs.com/package/react-use-cookie#getcookie

const BorrowerService = {
  verify: ({ taxId, sbaNumber }) =>
    axios.get(`${BORROWER_API_BASE_URL}/verify/${taxId}/${sbaNumber}`),

  register: (data) => axios.post(`${BORROWER_API_BASE_URL}/register`, data),

  getOverview: ({ username }) =>
    axios.get(
      `${BORROWER_API_BASE_URL}/dash/${username}`,
      getAuthenHeaderConfig()
    ),

  getPaymentHistory: ({ loanId }) =>
    axios.get(
      `${SVCING_LOANS_API_BASE_URL}/paymentHistory/${loanId}`,
      getAuthenHeaderConfig()
    ),

  getLoanDetails: ({ loanId }) =>
    axios.get(
      `${SVCING_LOANS_API_BASE_URL}/loanDetails/${loanId}`,
      getAuthenHeaderConfig()
    ),

  getStatements: ({ loanId }) =>
    axios.get(
      `${SVCING_LOANS_API_BASE_URL}/loanStatements/${loanId}`,
      getAuthenHeaderConfig()
    ),

  getStatementPDF: ({ s3Key }) => {
    const myParams = { pdfS3Key: s3Key };
    const promise = axios.get(
      `${SVCING_LOANS_API_BASE_URL}/statementPDF`,
      getAuthHeaderBlobResponseTypeAndParamsConfig(myParams)
    );
    return promise;
  },
};

export default BorrowerService;
