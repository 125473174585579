import { useState } from "react";
import { useForm } from "react-hook-form";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import makeStyles from "@mui/styles/makeStyles";

import CopyrightLine from "../components/CopyrightLine";
import PageLayout from "../components/PageLayout";

import LoginSplash from "../../static/login.jpg";
import { EMAIL_REGEX } from "../../utils/regexValidations";
import { usePostForgotPasswordMutation } from "../../borrower/borrowerApi";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${LoginSplash})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  body: {
    margin: "20px auto",
  },
}));

const ForgotPasswordPage = () => {
  const [posted, setPosted] = useState(false);
  const [postForgotPassword, postForgotPasswordResult] =
    usePostForgotPasswordMutation();
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleForgetPassword = async (data) => {
    postForgotPassword({
      email: data.email,
    })
      .unwrap()
      .then(() => {
        setPosted(true);
      });
  };

  const emailForm = register("email", {
    required: true,
    pattern: {
      value: EMAIL_REGEX,
      message: "Does not match email format",
    },
  });

  const { isLoading } = postForgotPasswordResult;

  return (
    <PageLayout>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot my password
          </Typography>
          {posted ? (
            <Typography className={classes.body} variant="body">
              Sent! Check your email inbox for further instructions.
            </Typography>
          ) : (
            <form
              className={classes.form}
              noValidate
              onSubmit={handleSubmit(handleForgetPassword)}
            >
              <TextField
                name={emailForm.name}
                onBlur={emailForm.onBlur}
                onChange={emailForm.onChange}
                ref={emailForm.ref}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                autoComplete="email"
                autoFocus
                error={errors.email}
                helperText={errors.email ? errors.email.message : ""}
              />
              <LoadingButton
                loading={isLoading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Send password reset
              </LoadingButton>
            </form>
          )}
          <Link href="/register" variant="body2">
            Don&apos;t have an account? Register
          </Link>
          <Box mt={5}>
            <CopyrightLine />
          </Box>
        </div>
      </Grid>
    </PageLayout>
  );
};

export default ForgotPasswordPage;
