import PropTypes from "prop-types";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";

import PageLayout from "../../app/components/PageLayout";
import {
  useGetPaymentMethodQuery,
  useGetProfileQuery,
  usePostPaymentMethodMutation,
  usePutPaymentMethodMutation,
} from "../../borrower/borrowerApi";
import { authSelector } from "../../auth/authSelectors";
import { ROUTING_NUMBER_REGEX } from "../../utils/regexValidations";
import exampleCheckImage from "../../static/example_check.png";

const useStyles = makeStyles({
  body: {
    display: "block",
    margin: "20px auto",
    maxWidth: "50%",
    textAlign: "left",
  },
  h2: {
    color: "#1769aa",
    display: "block",
    fontWeight: 500,
    fontSize: "20pt",
    margin: "20px 0 ",
  },
  h3: {
    display: "block",
    fontSize: "16pt",
    marginBottom: "10px",
  },
  submitButtonStack: {
    marginTop: "20px",
  },
  agreeLabel: {
    fontSize: "10pt",
  },
});

const AddAutopayPage = ({ isEdit }) => {
  const classes = useStyles();
  const { loanId, sbaLoanNumber } = useParams();
  const navigate = useNavigate();
  const [postPaymentMethod] = usePostPaymentMethodMutation();
  const [putPaymentMethod] = usePutPaymentMethodMutation();
  const { data: paymentMethodData, isLoading: paymentInfoLoading } =
    useGetPaymentMethodQuery(loanId, {
      skip: !isEdit,
    });
  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm();
  const accountNumber = useRef({});
  accountNumber.current = watch("bankAccountNumber", "");

  const { username } = useSelector(authSelector);
  const { data: profileData, isLoading: profileDataLoading } =
    useGetProfileQuery(username);

  if (paymentInfoLoading || profileDataLoading) {
    return <CircularProgress />;
  }

  const { id } = profileData;
  let defaultValues = {};
  if (paymentMethodData) {
    defaultValues = paymentMethodData;
  }

  const handlePaymentMethod = (data) => {
    const httpMethod = isEdit ? putPaymentMethod : postPaymentMethod;
    httpMethod({
      loanId: Number.parseInt(loanId, 10),
      id: isEdit ? paymentMethodData.id : id,
      ...data,
    })
      .unwrap()
      .then(() => {
        navigate(`/autopay/${loanId}/${sbaLoanNumber}`);
      })
      .catch(() => {
        toast.error(
          "Something went wrong. Please double-check the form. If the error persists, contact an administrator.",
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );
      });
  };

  return (
    <PageLayout>
      <Grid className={classes.body} item xs={12}>
        <Stack spacing={1}>
          <Typography className={classes.h2} variant="h2">
            {isEdit
              ? "Edit account information for AutoPay"
              : "Add an account for AutoPay"}
          </Typography>
          {!isEdit && (
            <Typography variant="body1">
              Adding an account will allow you to make your loan payments
              electronically.
            </Typography>
          )}
          <Typography className={classes.h3} variant="h3l">
            Account information
          </Typography>
          <form noValidate onSubmit={handleSubmit(handlePaymentMethod)}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Select your account type:
              </FormLabel>
              <Controller
                defaultValue={defaultValues.bankAccountType}
                rules={{ required: true }}
                control={control}
                name="bankAccountType"
                render={({ field }) => (
                  <RadioGroup
                    // TODO: fix uncontrolled / controlled switching
                    aria-label="accountType"
                    {...field}
                  >
                    <FormControlLabel
                      value="PC"
                      control={<Radio />}
                      label="Personal Checking"
                    />
                    <FormControlLabel
                      value="CC"
                      control={<Radio />}
                      label="Corporate Checking"
                    />
                  </RadioGroup>
                )}
              />

              <TextField
                {...register("recipientName", {
                  required: true,
                })}
                id="account-name"
                label="Account name"
                variant="standard"
                defaultValue={defaultValues.recipientName}
              />
              <TextField
                {...register("routingNumber", {
                  required: true,
                  pattern: {
                    value: ROUTING_NUMBER_REGEX,
                    message: "Not a valid routing number",
                  },
                })}
                id="routing-number"
                label="Routing number"
                variant="standard"
                defaultValue={defaultValues.routingNumber}
                error={errors.routingNumber}
                helperText={
                  errors.routingNumber ? errors.routingNumber.message : ""
                }
              />
              <TextField
                {...register("bankAccountNumber", {
                  required: true,
                })}
                id="bankAccountNumber"
                label="Account number"
                variant="standard"
              />
              <TextField
                {...register("account-number-reenter", {
                  required: true,
                  validate: (value) =>
                    value === accountNumber.current ||
                    "The account numbers do not match",
                })}
                id="account-number-reenter"
                label="Re-enter account number"
                variant="standard"
                error={errors["account-number-reenter"]}
                helperText={
                  errors["account-number-reenter"]
                    ? errors["account-number-reenter"].message
                    : ""
                }
              />
              <br />
              <Stack direction="row" alignItems="flex-start">
                <Controller
                  name="agree"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Checkbox {...field} />}
                />
                <Typography className={classes.agreeLabel} variant="body2">
                  I (we) hereby authorize American Lending Center LLC (THE
                  COMPANY) to initiate debit and/or credit entries to my (our)
                  checking/savings accounts at the financial institution listed
                  below (THE FINANCIAL INSTITUTION), and, if necessary, initiate
                  adjustments for any transactions debited and/or credited in
                  error. This authority will remain in effect until THE COMPANY
                  is notified by me (us) in writing to cancel it in such time as
                  to afford THE COMPANY and THE FINANCIAL INSTITUTION a
                  reasonable opportunity to act on it.
                </Typography>
              </Stack>
            </FormControl>
            <Stack
              className={classes.submitButtonStack}
              spacing={2}
              direction="row"
            >
              <Button type="submit" variant="contained">
                Submit
              </Button>
              <Button onClick={() => navigate(-1)} variant="outlined">
                Cancel
              </Button>
            </Stack>
          </form>
          <img alt="Example Check" src={exampleCheckImage} />
        </Stack>
      </Grid>
    </PageLayout>
  );
};

AddAutopayPage.propTypes = {
  isEdit: PropTypes.bool,
};

export default AddAutopayPage;
