import { createSelector } from "reselect";

export const authSelector = (state) => state.auth;

export const isAuthed = createSelector(authSelector, (auth) => auth.isAuthed);

export const usernameSelector = createSelector(
  authSelector,
  (auth) => auth.username
);

export const jwtSelector = createSelector(authSelector, (auth) => auth.jwt);
