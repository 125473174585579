import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const CopyrightLine = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {"Copyright © "}
    {new Date().getFullYear()}&nbsp;
    <Link color="inherit" href="https://americanlendingcenter.com/">
      American Lending Center
    </Link>
  </Typography>
);

export default CopyrightLine;
