import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GoogleMapReact from "google-map-react";

import PageLayout from "../../app/components/PageLayout";
import DashboardTabHeader from "../../dashboard/components/DashboardTabHeader";
import "../styles/map.css";

const useStyles = makeStyles({
  item: {
    height: "80%",
    textAlign: "left",
    padding: "40px",
  },
  textbox: {
    color: "#666",
    display: "block",
    height: "100%",
    backgroundColor: "#eee",
    padding: "40px",
  },
  heading: {
    fontWeight: 500,
    fontSize: "1.8em",
    marginBottom: "1em",
  },
});
const location = {
  address: "18881 Von Karman Ave, Suite 1050, Irvine, CA 92612",
  lat: 33.67121,
  lng: -117.8583,
};

const LocationPin = ({ text }) => (
  <div className="pin">
    <LocationOnIcon />
    <Typography className="pin-text">{text}</Typography>
  </div>
);

LocationPin.propTypes = {
  text: PropTypes.string,
};

const HelpPage = () => {
  const classes = useStyles();

  return (
    <PageLayout>
      <DashboardTabHeader />
      <Grid className={classes.item} item xs={6}>
        <div className={classes.textbox}>
          <Typography className={classes.heading} variant="h3">
            Contact Us
          </Typography>
          <Typography variant="body">
            (562) 449-0139 English
            <br />
            (562) 713-6220 Spanish
            <br />
            (562) 787-8938 Chinese
            <br />
            (408) 691-1029 Vietnamese
            <br />
            (562) 449-0079 Fax
            <br />
            <br />
            info@americanlendingcenter.com
            <br />
            <br />
            {location.address}
          </Typography>
        </div>
      </Grid>
      <Grid className={classes.item} item xs={6}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCG-gt5pcE-vG_HMxFG0De21E7UJiPMo4E" }}
          defaultCenter={location}
          defaultZoom={14}
        >
          <LocationPin
            lat={location.lat}
            lng={location.lng}
            text={location.address}
          />
        </GoogleMapReact>
      </Grid>
    </PageLayout>
  );
};

export default HelpPage;
