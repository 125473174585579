import jwtdecode from "jwt-decode";
import AuthService from "../services/AuthService";
import {
  SET_AUTH,
  SET_JWT,
  SET_USERNAME,
  SET_USERROLE,
} from "./authActionTypes";

export const setAuth = (isAuthed) => ({
  type: SET_AUTH,
  payload: isAuthed,
});

export const setJwt = (jwt) => ({ type: SET_JWT, payload: jwt });

export const setUsername = (username) => ({
  type: SET_USERNAME,
  payload: username,
});

export const setRole = (userRole) => ({
  type: SET_USERROLE,
  payload: userRole,
});

export const login = (user) => async (dispatch) => {
  try {
    const jwtToken = await AuthService.authenticateAsync(user);

    if (jwtToken !== null) {
      const userRole = jwtdecode(jwtToken).role;
      dispatch(setAuth(true));
      dispatch(setJwt(jwtToken));
      dispatch(setUsername(user.username));
      dispatch(setRole(userRole));
      return true;
    }

    dispatch(setAuth(false));
    return false;
  } catch (e) {
    dispatch(setAuth(false));
    // eslint-disable-next-line
    console.error(e);
    throw e;
  }
};

export const logout = () => (dispatch) => {
  AuthService.unauthenticate();
  dispatch(setAuth(false));
  dispatch(setJwt(""));
  dispatch(setUsername(""));
  dispatch(setRole(""));
};
