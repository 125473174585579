import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Grid, Tab, Tabs } from "@mui/material";

const useStyles = makeStyles({
  grid: {
    maxHeight: 64,
  },
});

const DashboardTabHeader = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const handleChange = (evt, newValue) => {
    setValue(newValue);
  };

  const handleClick = (path) => () => navigate(path);

  return (
    <Grid item className={classes.grid} xs={12}>
      <Tabs value={value} onChange={handleChange}>
        <Tab
          label="Dashboard"
          id="dashboard"
          onClick={handleClick("/dashboard")}
        />
      </Tabs>
    </Grid>
  );
};

export default DashboardTabHeader;
